body {
  padding-top: 70px;
  background: #fff;
}
h2,
h4,
h5 {
  font-weight: bold;
}
#logo {
  height: 100px;
  margin-left: 0;
}
.margin {
  margin: 10px;
}
[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
  display: none !important;
}
/* DASHBOARD */
.metric-large {
  border: solid 1px lightgray;
  text-align: center;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  min-height: 140px;
  position: relative;
}
.metric-large.delivery-statuses {
  padding-bottom: 30px;
}
.metric-large.delivery-statuses ul {
  padding: 10px;
  list-style-type: none;
  text-align: left;
}
.metric-large.delivery-statuses ul li {
  border-bottom: 1px solid lightgray;
}
.metric-large.delivery-statuses ul li:last-child {
  border-bottom: none;
}
.metric-large h2 {
  font-size: 45pt;
  margin-top: 28px;
  font-weight: bold;
}
.metric-large label {
  text-align: center;
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
  border-top: solid 1px lightgray;
  background-color: #fafafa;
  background-image: -moz-linear-gradient(top, #fff, #f2f2f2);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fff), to(#f2f2f2));
  background-image: -webkit-linear-gradient(top, #fff, #f2f2f2);
  background-image: -o-linear-gradient(top, #fff, #f2f2f2);
  background-image: linear-gradient(to bottom, #fff, #f2f2f2);
  background-repeat: repeat-x;
  line-height: 30px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 85%;
  font-weight: bold;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
}
/* ORDER LIST*/
#order-search {
  text-align: center;
  margin-bottom: 30px;
}
.no-data {
  text-align: center;
  margin: 50px 0 0 50px;
  font-weight: bold;
}
#filter-buttons ul {
  margin: 20px 0 0 0;
  display: inline-block;
}
#filter-buttons ul li a {
  line-height: 35px;
}
#filter-buttons ul li.inactive a {
  background-color: #f9f9f9;
}
/* ORDER DETAILS */
#sticky_footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 190px;
  background: ghostwhite;
  border-top: solid 1px lightgray;
  text-align: center;
  padding: 20px 0 20px 0;
}
.highlight {
  background-color: #bd362f;
  border-top: solid 1px #eed3d7;
  border-bottom: solid 1px #eed3d7;
  margin-bottom: 2px;
  color: white;
}
#footerFix {
  height: 230px;
}
#sticky_footer form {
  display: inline-block;
}
.swipe {
  overflow: hidden;
  visibility: hidden;
  position: relative;
}
.swipe-wrap {
  overflow: hidden;
  position: relative;
}
.swipe-wrap > div {
  float: left;
  width: 100%;
  position: relative;
}
.hasMore {
  background-image: url(/admin/layout/img/swipe-left.png), url(/admin/layout/img/swipe-right.png);
  background-repeat: no-repeat;
  background-position: left, right;
}
tr.no-weight > td {
  background-color: #F7ECB4 !important;
}
/* USER DETAILS */
.club-member-info {
  color: #8E4EC5;
  font-size: 19pt;
  font-weight: bold;
}
/* GLOBAL */
table.table.touch tbody > tr {
  cursor: pointer;
}
table.table.touch td {
  padding: 20px 8px 20px 8px;
}
ul.pagination {
  list-style-type: none;
}
.btn.touch {
  display: inline-block;
  width: 140px;
  margin: 0 20px 0 20px;
  /*padding-top: 25px;
    padding-bottom: 40px;*/
  height: 120px !important;
  white-space: normal;
}
a.btn.touch {
  height: 50px !important;
}
.btn.touch.wide {
  width: 150px !important;
}
.right-aligned {
  text-align: right !important;
}
.loading {
  margin: 50px 0 0 50px;
  height: 50px;
  background-image: url(/layout/img/ajax-loader.gif);
  background-position: center;
  background-repeat: no-repeat;
}
.col-centered {
  float: none;
  margin: 0 auto;
}
.col-fixed {
  /* custom width */
  width: 320px;
}
.col-min {
  /* custom min width */
  min-width: 320px;
}
.col-max {
  /* custom max width */
  max-width: 320px;
}
.modal form {
  margin: 0;
}
.modal select {
  margin-top: 3px;
  font-size: 20px;
  height: 48px;
  width: 100%;
}
.modal .big {
  font-size: 18px;
}
.form-search {
  margin-bottom: 10px;
}
.prescriptionsList .prescriptionNoteHeader {
  background-color: #fff6ac;
  margin-bottom: 30px;
  padding: 10px 10px 16px;
  color: black;
  z-index: 10;
  /*box-shadow: 5px -3px 8px -2px rgba(0, 0, 0, 0.14);*/
}
.prescriptionsList .prescriptionNoteHeader .headerFieldSet {
  background-color: #fffbda;
  border: 1px solid #d3d062;
  border-radius: 8px;
  padding: 15px;
}
.prescriptionsList .prescription {
  background-color: white;
  padding: 15px;
  border: 1px solid #d3d062;
  border-radius: 8px;
  margin-bottom: 15px;
}
.panel-default > .panel-heading {
  /* Bootstrap 2 style */
  background-image: -moz-linear-gradient(top, #fff, #f2f2f2);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fff), to(#f2f2f2));
  background-image: -webkit-linear-gradient(top, #fff, #f2f2f2);
  background-image: -o-linear-gradient(top, #fff, #f2f2f2);
  background-image: linear-gradient(to bottom, #fff, #f2f2f2);
}
.panel-default > .panel-heading .panel-title {
  padding-top: 3px;
}
/* REVIEWS */
h2 .product-sku {
  margin-left: 25px;
}
.panel-group .panel.review + .panel.review {
  margin-top: 15px;
}
.review .panel-body {
  position: relative;
}
.review .rating {
  cursor: default;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}
table .rating-wrapper {
  float: left;
  display: inline-block;
  position: relative;
  white-space: nowrap;
  margin-right: 15px;
}
table .rating {
  cursor: default;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  margin: 0;
  padding: 0;
}
table .rating.fill {
  left: 0;
  position: absolute;
  overflow: hidden;
}
table .rating .icon,
table .rating .glyphicon {
  margin: 0 2px;
  margin-top: -5px;
}
.review .review-text {
  margin-bottom: 15px;
}
.review .review-details {
  padding-top: 3px;
  margin-bottom: 30px;
}
.review .product,
.review .state {
  margin-left: 20px;
  margin-right: 20px;
}
.review .product .product-sku {
  margin-left: 10px;
}
.nav-userinfo-container {
  color: #777777;
}
.nav-userinfo-container .glyphicon-user {
  margin-right: 5px;
}
.nav-userinfo-container .divider {
  height: 20px;
  margin-top: 15px;
  margin-bottom: 15px;
  border-left: 1px solid #777777;
  border-right: 1px solid #ffffff;
}
.login-container {
  background-color: #f8f8f8;
  border-color: #e7e7e7;
  padding: 30px;
  margin-top: 200px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.login-container #admin-label {
  width: 40px;
  padding-left: 94px;
  font-weight: bold;
  font-size: 18px;
  margin: -5px auto 20px;
}
.login-container #logo {
  display: block;
  margin: 0 auto;
}
.redirect-cell {
  cursor: pointer;
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;
}
.voucherCodesFormLabel {
  min-width: 180px;
}
.voucherDetailsFormLabel {
  min-width: 215px;
}
.inactiveVoucherGroup {
  color: #8b8b8b;
}
#voucherTargets {
  max-width: 750px;
}
#voucherTargets .form-group label {
  display: block;
}
.voucherTargetTextarea {
  width: 391px;
  height: 58px;
}
.voucherTargetsPreview {
  float: right;
}
.voucherTargetsPreview .found span {
  color: #1a9307;
}
.voucherTargetsPreview .notfound span {
  color: #d80a0a;
}
