﻿/* REVIEWS */

h2 .product-sku {
    margin-left: 25px;
}

.panel-group .panel.review + .panel.review {
    margin-top: 15px;
}

.review .panel-body {
    position: relative;
}

.review .rating {
    cursor: default;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

table .rating-wrapper {
    position: relative;
    float: left;
    display: inline-block;
    position: relative;
    white-space: nowrap;
    margin-right: 15px;
}

table .rating {
    cursor: default;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    margin: 0;
    padding: 0;
}

table .rating.fill {
    left: 0;
    position: absolute;
    overflow: hidden;
}

table .rating .icon, table .rating .glyphicon {
    margin: 0 2px;
    margin-top: -5px;
}

.review  .review-text {
    margin-bottom: 15px;
}

.review .review-details {
    padding-top: 3px;
    margin-bottom: 30px;
}

.review .product, .review .state {
    margin-left: 20px;
    margin-right: 20px;
}

.review .product .product-sku {
    margin-left: 10px;
}